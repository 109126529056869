const RoguesGallery = ({ image, students }) => (
  <section className="bg-near-white pv5">
    <div className="container">
      <div className="rogues-grid pv4 sm:mv3">
        <h2 className="section-heading mb5">Rogues Gallery</h2>

        <Img
          fluid={image.image.childImageSharp.fluid}
          alt={image.alt}
          objPosition={image.objPosition}
          className="measure-wide"
        />

        <Pitch />

        <Students image={image} students={students} />
      </div>
    </div>
  </section>
)

/*
 *
 * Pitch
 *
 */

const Pitch = () => (
  <p className="mt5 measure-medium lh-copy">
    Here are just a few of Patrick's students. If you would like your name added to
    this list, please contact Patrick at{` `}
    <Anchor href="info@jpatrickraftery.com" className="link-inline">
      info@jpatrickraftery.com
    </Anchor>:
  </p>
)

/*
 *
 * Students
 *
 */

const Students = ({ students }) => (
  <ul className="mt4 pt1">
    {students.map((student, i) => <Student key={i} student={student.node} />)}
  </ul>
)

/*
 *
 * Student
 *
 */

const Student = ({ student }) => (
  <li className="mt3 pt2">
    <Anchor href={student.link} className="link-inline f4">
      {student.name}
    </Anchor>
  </li>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import Anchor from '../components/Anchor'
import Img from '../components/Img'

export default RoguesGallery
