const Scholarship = ({ scholarship }) => (
  <section className="container pv5 mt4 mb3">
    <h2 className="section-heading mb5 sm:pt3">Jane Raftery Award</h2>

    <div className="measure-wide">
      <Img fluid={scholarship.image.childImageSharp.fluid} alt={scholarship.alt} />
      <Pitch scholarship={scholarship} />
    </div>

    <Obituary obituary={scholarship.obituary} />

    <Recipients
      recipients={scholarship.recipients}
      invitation={scholarship.invitation}
    />
  </section>
)

/*
 *
 * Pitch
 *
 */

const Pitch = ({ scholarship }) => (
  <p
    className="mt4 pt3 measure lh-copy"
    dangerouslySetInnerHTML={{ __html: scholarship.description }}
  />
)

/*
 *
 * Obituary
 *
 */

const Obituary = ({ obituary }) => (
  <Collapse
    renderContent={() => <HiddenContent obituary={obituary} />}
    renderToggle={(expanded, handleToggle) =>
      !expanded && <AboutJane handleToggle={handleToggle} />
    }
  />
)

/*
 *
 * Hidden Content
 * 
 */

const HiddenContent = ({ obituary }) => (
  <Fragment>
    <h3 className="section-subheading mv4 pt3">About Jane</h3>
    <p className="mt3 measure lh-copy">{obituary}</p>
  </Fragment>
)

/*
 *
 * Read More
 *
 */

const AboutJane = ({ handleToggle }) => (
  <div className="mt4 pt3">
    <button onClick={handleToggle} className="link">
      More about Jane
    </button>
  </div>
)

/*
 *
 * Recipients
 * 
 */

const Recipients = ({ recipients, invitation }) => (
  <Fragment>
    <h3 className="section-subheading mv4 pt3">Past Recipients</h3>

    <ul>
      {recipients.map((recipient, i) => <Recipient key={i} recipient={recipient} />)}
    </ul>

    <p
      className="mt4 measure lh-copy"
      dangerouslySetInnerHTML={{ __html: invitation }}
    />
  </Fragment>
)

/*
 *
 * Recipient
 *
 */

const Recipient = ({ recipient }) => (
  <li className="mt3">
    <p>
      <span className="fw7">{recipient.year}:&nbsp;</span>
      {recipient.name}
    </p>
  </li>
)

/*
 *
 * Imports & Exports
 * 
 */

import React, { Fragment } from 'react'

import Collapse from '../components/Collapse'
import Img from '../components/Img'

export default Scholarship
