const Schedule = ({ events }) => {
  let limit = 3

  // Wait for the document to exist
  if (typeof window !== `undefined`) {
    // Create a test element to check for grid support
    const testEl = document.createElement(`div`)
    testEl.style.display = `grid`

    // If grid is supported, show more items initially on large screens
    if (testEl.style.display === `grid`) {
      if (window.matchMedia(`(min-width: 48em)`).matches) {
        limit = 4
      }
    }
  }

  return (
    <section className="container mv4 pv5">
      <h2 className="section-heading sm:mt3">Schedule & History</h2>

      <Limit items={events} limit={limit} increment={limit}>
        {(visibleItems, limited, handleLoadMore) => (
          <Fragment>
            <Events events={visibleItems} />
            {limited && <LoadMore handleLoadMore={handleLoadMore} />}
          </Fragment>
        )}
      </Limit>
    </section>
  )
}

/*
 *
 * Events
 *
 */

const Events = ({ events }) => (
  <ul className="schedule-grid mb4 sm:pb3">
    <TransitionGroup component={null}>
      {events.map(event => (
        <Mount key={event.node.dates} animateSpace={false} appear={false}>
          <Event event={event.node} />
        </Mount>
      ))}
    </TransitionGroup>
  </ul>
)

/*
 *
 * Event
 *
 */

const Event = ({ event }) => (
  <li className="mt5 measure-medium" style={{ transformOrigin: `left` }}>
    <h3 className="lh-title f3 fw4">
      <Anchor href={event.link} className="link-inline">
        <span dangerouslySetInnerHTML={{ __html: event.organization }} />
      </Anchor>
    </h3>

    {event.project && <p className="pt1 f4 lh-copy">{event.project}</p>}

    <p className="mt3 lh-copy">{event.dates}</p>

    <dl className="mt3 lh-copy">
      {event.details.map((detail, i) => {
        return (
          <Fragment key={i}>
            <dt className="fl b">{detail.name}:&nbsp;</dt>
            <dd>{detail.value}</dd>
          </Fragment>
        )
      })}
    </dl>
  </li>
)

/*
 *
 * Load More
 *
 */

const LoadMore = ({ handleLoadMore }) => (
  <button onClick={handleLoadMore} className="link mt4 sm:mb3">
    See more events
  </button>
)

/*
 *
 * Imports & Exports
 * 
 */

import React, { Fragment } from 'react'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import Anchor from '../components/Anchor'
import Limit from '../components/Limit'
import Mount from '../components/Mount'

export default Schedule
