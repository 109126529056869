const Bio = ({ paragraphs }) => (
  <section className="bg-near-white pv5">
    <div className="container pv4 sm:mv3">
      <h2 className="section-heading mb4 pb2">Bio</h2>

      <VisibleParagraphs paragraphs={paragraphs.slice(0, 2)} />

      <Collapse
        renderContent={() => <HiddenParagraphs paragraphs={paragraphs.slice(2)} />}
        renderToggle={(expanded, handleToggle) =>
          !expanded && <ReadMore handleToggle={handleToggle} />
        }
      />
    </div>
  </section>
)

/*
 *
 * Visible Paragraphs
 *
 */

const VisibleParagraphs = ({ paragraphs }) => {
  return paragraphs.map((paragraph, i) => {
    return (
      <p
        key={i}
        dangerouslySetInnerHTML={{ __html: paragraph }}
        className="mt3 measure lh-copy"
      />
    )
  })
}

/*
 *
 * Hidden Paragraphs
 * 
 */

const HiddenParagraphs = ({ paragraphs }) => {
  return paragraphs.map((paragraph, i) => {
    return (
      <p
        key={i + 2} // index restarts at 0 after slice
        dangerouslySetInnerHTML={{ __html: paragraph }}
        className="mt3 measure lh-copy"
      />
    )
  })
}

/*
 *
 * Read More
 *
 */

const ReadMore = ({ handleToggle }) => (
  <button onClick={handleToggle} className="link mt5">
    Read more
  </button>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import Collapse from '../components/Collapse'

export default Bio
