const TeachingPage = ({ data }) => {
  const hero = data.teachingYaml.teachingHero

  return (
    <Base>
      <PageMetadata page={data.metadata.siteMetadata.teachingPage} />

      <main id="main-content" tabIndex="-1" className="sans-serif">
        <Hero
          heading={hero.heading}
          image={hero.image.childImageSharp.fluid}
          alt={hero.alt}
          objPosition={hero.objPosition}
          quote={hero.quote}
          source={hero.source}
          link={hero.link}
        />

        <Bio paragraphs={data.teachingYaml.teachingBio} />

        <Schedule events={data.allEventsTeachingYaml.edges} />

        <RoguesGallery
          image={data.teachingYaml.roguesImage}
          students={data.allStudentsYaml.edges}
        />

        <Scholarship scholarship={data.scholarshipYaml} />
      </main>
    </Base>
  )
}

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query TeachingQuery {
    metadata: site {
      siteMetadata {
        teachingPage {
          description
          title
          url
        }
      }
    }
    teachingYaml {
      teachingHero {
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        objPosition
        quote
        source
        link
      }
      teachingBio
      roguesImage {
        image {
          childImageSharp {
            fluid(maxWidth: 735) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        objPosition
      }
    }
    allEventsTeachingYaml {
      edges {
        node {
          dates
          project
          organization
          link
          details {
            name
            value
          }
        }
      }
    }
    allStudentsYaml {
      edges {
        node {
          name
          link
        }
      }
    }
    scholarshipYaml {
      heading
      subheading
      image {
        childImageSharp {
          fluid(maxWidth: 905) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      objPosition
      description
      invitation
      obituary
      recipients {
        name
        year
      }
    }
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

import Bio from '../sections/TeachingBio'
import RoguesGallery from '../sections/TeachingRogues'
import Schedule from '../sections/TeachingSchedule'
import Scholarship from '../sections/TeachingScholarship'

import Base from '../components/Base'
import Hero from '../components/Hero'
import PageMetadata from '../components/PageMetadata'

export default TeachingPage
